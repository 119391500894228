h1 {
  overflow-wrap: break-word;

  font-weight: 700;
  font-size: 2.25rem;
  line-height: 2.5rem;
  font-family: Kalam, cursive;
}

h2 {
  font-weight: 700;
  font-size: 1.35rem;
}
