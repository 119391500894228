.heart-twos-clip {
  mask-position: center;
  mask-image: url("/public/assets/imageMask.png");
  mask-size: contain;
  mask-repeat: no-repeat;
}
.programma-heart-twos-clip {
  mask-position: center;
  mask-image: url("/public/assets/imageMask2.png");
  mask-size: contain;
  mask-repeat: no-repeat;
}

.heart-clip {
  mask:
    radial-gradient(at 70% 31%, #000 29%, #0000 30%),
    radial-gradient(at 30% 31%, #000 29%, #0000 30%),
    linear-gradient(#000 0 0) bottom/100% 50% no-repeat;
  clip-path: polygon(-41% 0, 50% 91%, 141% 0);
}

:root {
  --figure-size: 80;
}

.background-figures {
  position: relative;
  z-index: -1;
  overflow: hidden;
}

.background-figures > * {
  z-index: 10;
}

.background-figures::after,
.background-figures::before {
  z-index: -1;
  position: absolute;
  width: calc(var(--figure-size) * 0.5rem);
  height: calc(var(--figure-size) * 1rem);
  /* background-color: red; */

  font-family: "Kalam", "cursive";
  font-size: calc(var(--figure-size) * 1rem);

  opacity: 60%;

  transform: scaleX(1.5);
}

.background-figures::after {
  content: "0";
  color: #ec5f3d;
  transform: rotateX(180deg);

  top: 0;
  right: 0;
  translate: 35% -40%;
}
.background-figures::before {
  content: "2";
  color: #ff812b;

  bottom: 0;
  left: 0;
  translate: -45% 50%;
}
